export type UserActionType = ISetExistingMobile | ISetExistingUser;

export interface IUserState {
  isExistingMobile: boolean;
  isExistingUser: boolean;
  isRegisterOtpError: boolean;
  isRegisteringPotentialDuplicate: boolean;
}

export const defaultState: Partial<IUserState> = {
  isExistingMobile: false,
  isExistingUser: false,
  isRegisterOtpError: false,
  isRegisteringPotentialDuplicate: false,
};

type StateReducer<T = any, U = any> = (state: T, action: U) => T;

export const CLEAR_USER_STATE: StateReducer<Partial<IUserState>> = () => {
  return defaultState;
};

interface ISetExistingMobile {
  type: 'SET_EXISTING_MOBILE';
  isExistingMobile: boolean;
}
export const SET_EXISTING_MOBILE: StateReducer<
  IUserState,
  ISetExistingMobile
> = (state, action) => {
  return {
    ...state,
    isExistingMobile: action.isExistingMobile,
  };
};

interface ISetExistingUser {
  type: 'SET_EXISTING_USER';
  isExistingUser: boolean;
}
export const SET_EXISTING_USER: StateReducer<IUserState, ISetExistingUser> = (
  state,
  action
) => {
  return {
    ...state,
    isExistingUser: action.isExistingUser,
  };
};

interface ISetRegisterOtpError {
  type: 'SET_REGISTER_OTP_ERROR';
  isRegisterOtpError: boolean;
}
export const SET_REGISTER_OTP_ERROR: StateReducer<
  IUserState,
  ISetRegisterOtpError
> = (state, action) => {
  return {
    ...state,
    isRegisterOtpError: action.isRegisterOtpError,
  };
};

interface ISetRegisteringPotentialDuplicate {
  type: 'SET_REGISTERING_POTENTIAL_DUPLICATE';
  isRegisteringPotentialDuplicate: boolean;
}
export const SET_REGISTERING_POTENTIAL_DUPLICATE: StateReducer<
  IUserState,
  ISetRegisteringPotentialDuplicate
> = (state, action) => {
  return {
    ...state,
    isRegisteringPotentialDuplicate: action.isRegisteringPotentialDuplicate,
  };
};
