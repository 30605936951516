export type MessagesActionType = IAddMessage | IRemoveMessage;

export type MessageType = 'error' | 'info' | 'warning';

export interface IMessage {
  id: symbol;
  msg: string;
  type: MessageType;
}
export interface IMessagesState {
  messages: IMessage[];
}
export const defaultState: IMessagesState = {
  messages: [],
};

type StateReducerType<T = any, U = any> = (state: T, action: U) => T;

interface IAddMessage {
  message: string;
  messageType: MessageType;
  type: string;
}
export const ADD_MESSAGE: StateReducerType<IMessagesState, IAddMessage> = (
  state,
  action
) => {
  return {
    ...state,
    messages: [
      ...state.messages,
      { id: Symbol(), msg: action.message, type: action.messageType },
    ],
  };
};

export const CLEAR_MESSAGES_STATE: StateReducerType<IMessagesState> = () => {
  return defaultState;
};

interface IClearMessagesWithTypeExclusion {
  excluded: MessageType[];
  type: string;
}
export const CLEAR_MESSAGES_WITH_TYPE_EXCLUSION: StateReducerType<
  IMessagesState,
  IClearMessagesWithTypeExclusion
> = (state, action) => {
  return {
    ...state,
    messages: state.messages.filter((msg) =>
      action.excluded.includes(msg.type)
    ),
  };
};

interface IRemoveMessage {
  id: symbol;
  type: string;
}
export const REMOVE_MESSAGE: StateReducerType<
  IMessagesState,
  IRemoveMessage
> = (state, action) => {
  return {
    ...state,
    messages: state.messages.filter((msg) => msg.id !== action.id),
  };
};
