import { combineReducers } from 'redux';

import * as mReducers from './messages';
import { IMessagesState, MessagesActionType } from './messages';
import * as uReducers from './user';
import { IUserState, UserActionType } from './user';

const { defaultState: messagesDefaultState, ...messagesReducers } = mReducers;
const { defaultState: formDefaultState, ...userReducers } = uReducers;

type ActionTypes = MessagesActionType | UserActionType;

// TODO: how to infer correct actions for each reducer type
type ReducerFunc = {
  [key: string]: any;
};

function decorateReducer<T = any>(reducer: ReducerFunc, defaultState: T) {
  return (state = defaultState, action: ActionTypes): T => {
    try {
      const callable = reducer[action.type];

      if (typeof callable === 'function') {
        return callable(state, action);
      }

      return state;
    } catch (error) {
      console.error(
        `There was an error calling the reducer for ${action.type}`,
        error
      );
      return state;
    }
  };
}

const reducers = combineReducers({
  messages: decorateReducer(messagesReducers, messagesDefaultState),
  user: decorateReducer(userReducers, formDefaultState),
});

export default reducers;

export interface IRootState {
  messages: IMessagesState;
  user: Partial<IUserState>;
}
