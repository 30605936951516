export const {
  AUTH0_TENANT = '',
  AUTH0_CONSUMER_CLIENT_ID = '',
  AUTH0_CONSUMER_CONNECTION = '',

  ADDRESS_AUTO_COMPLETE_URL = '',
  CONSUMER_EMAIL_STATUS_URL = '',
  CONSUMER_MOBILE_STATUS_URL = '',
  SESSION_STATE_URL = '',
  REGISTER_URL = '',
  SEND_OTP_URL = '',
  VERIFY_OTP_URL = '',
  CONSUMER_PORTAL_URL = '/consumer/portal',

  MIXPANEL_ENV = '',
  APPLICATION_NAME = '',

  SENTRY_DSN = '',

  COUNTRY_CODE = '',
  COUNTRY_MOBILE_PREFIX = '',
  MOBILE_PLACEHOLDER = '',

  EXTERNAL_HOME = '',
  PRIVACY_POLICY_LINK = '',
  TERMS_CONDITIONS_LINK = '',

  SUPPORT_EMAIL = '',

  NAME_VALIDATION_PATTERN = '',

  SKIP_ADDRESS_ON_REGISTER = '',
  REGISTER_ENABLED = 'false',
} = window.pb.config;

export const ACCOUNT_TYPE_KEY = 'pbAccountType';
export const ACCESS_TOKEN_KEY = 'pbAccessToken';
export const ID_TOKEN_KEY = 'pbIdToken';
export const USER_METADATA_KEY = 'pbUserMetadata';

export const CALLBACK_SEARCH_SESSION_KEY = 'callbackSearch';
export const FORM_MODULE_STATE_KEY = 'FORM_MODULE_STATE';
export const FORCE_RAPID_ADDRESS = 'forceRapidAddress';
export const ORDER_ID_KEY = 'pbCheckoutOrderId';
export const SESSION_ID_KEY = 'eSessionId';
export const CANCEL_URL_KEY = 'eCancelUrl';

export const LOGIN_REDIRECT_KEY = 'pbLoginRedirectUrl';
export const AUTH_CALLBACK_URL = `${window.location.origin}/auth/callback`;
