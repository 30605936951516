import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { initialize } from '@etika/browser-events';
import { AppLoader } from '@etika/component-library';

import store from './stores';
import { ACCOUNT_TYPE_KEY, SENTRY_DSN } from './config';

const accountType = 'consumer';
window.sessionStorage.setItem(ACCOUNT_TYPE_KEY, accountType);

initialize({
  analytics: {
    accountType,
  },
  monitoring: {
    dsn: SENTRY_DSN,
  },
});

const node = document.body.appendChild(document.createElement('div'));
const root = createRoot(node);

const App = React.lazy(
  () => import(/* webpackChunkName: "app" */ './components/App')
);

root.render(
  <StrictMode>
    <Provider store={store}>
      <AppLoader>
        <App />
      </AppLoader>
    </Provider>
  </StrictMode>
);
